<script setup lang="ts">
import Swal from "sweetalert2";
definePageMeta({
  name: "Login",
  title: "Login",
  path: "/",
  middleware: "guest",
  description: "Login to your account",
});

const login = ref("");
const password = ref("");
const { t, locale } = useI18n();

const title = computed(() => `Vendeo - ${t("pages.login.meta.title")}`);

useHead({
  title,
  meta: [
    { name: "description", content: t("pages.login.meta.description") },
    { name: "lang", content: locale.value },
    { name: "og:title", content: t("pages.login.meta.title") },
    { name: "og:description", content: t("pages.login.meta.description") },
    { name: "og:locale", content: locale.value },
  ],
});

const supabase = useSupabaseClient();
const config = useRuntimeConfig();
const user = useSupabaseUser();

watch(user, async () => {
  if (user.value?.user_metadata) {
    console.log("login", user.value?.user_metadata.onboarding);
    return await navigateTo(
      user.value?.user_metadata.onboarding ? "/app/storage" : "/onboarding",
    );
  }
});

const loading = ref(false);

const handleError = (error: string) => {
  Swal.fire({
    title: error,
    icon: "error",
    toast: true,
    position: "top-end",
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

const signin = async () => {
  loading.value = true;
  const { error } = await supabase.auth.signInWithPassword({
    email: login.value,
    password: password.value,
  });
  if (error) handleError(error.message);
  loading.value = false;
};

const signWithFacebook = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: "facebook",
    options: {
      redirectTo: config.public.appDomain,
    },
  });
  if (error) handleError(error.message);
};

const signWithGoogle = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: "google",
    options: {
      redirectTo: config.public.appDomain,
    },
  });
  if (error) handleError(error.message);
};
/*const signWithLinkedin = async () => {
  const { error, } = await supabase.auth.signInWithOAuth({
    provider: "linkedin",
    options: {
      redirectTo: config.public.appDomain,
    },
  });
  if (error) handleError(error.message);
  initGeneralStore();
};*/
</script>

<template>
  <div class="flex min-h-full flex-col justify-center py-12 px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <Logo />
      <h2
        class="mt-6 text-center text-3xl font-bold tracking-tight text-primary"
      >
        {{ $t("pages.login.title") }}
      </h2>
    </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md mt-12">
      <form class="space-y-6" @submit.prevent="signin">
        <div>
          <div class="mt-1">
            <input
              id="login"
              name="login"
              autocomplete="email"
              required
              placeholder="erwan@vendeo.io"
              class="input"
              v-model="login"
            />
          </div>
        </div>
        <div>
          <div class="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              placeholder="********"
              class="input"
              v-model="password"
            />
          </div>
        </div>
        <div class="flex items-center justify-end">
          <div class="text-sm">
            <NuxtLink
              :to="{ name: 'ForgotPassword' }"
              class="font-medium text-accent hover:text-accent-hover"
              >{{ $t("pages.login.forgot_password") }}
            </NuxtLink>
          </div>
        </div>

        <div>
          <button type="submit" class="btn-primary">
            {{ $t("pages.login.signin") }}
          </button>
        </div>
      </form>
      <NuxtLink :to="{ name: 'Signup' }" class="btn-secondary mt-6">
        {{ $t("pages.login.no_account") }}
      </NuxtLink>
      <div class="mt-12">
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-sm border-muted" />
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="bg-primary px-2 text-muted">{{
              $t("global.or_continue")
            }}</span>
          </div>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-2 gap-3">
        <div>
          <button type="button" class="btn-secondary" @click="signWithGoogle">
            <font-awesome-icon class="mr-2" :icon="['fab', 'fa-google']" :size="'sm'"></font-awesome-icon>
          </button>
        </div>
        <!--
        <div>
          <button type="button" class="btn-secondary" @click="signWithLinkedin">
            <i class="fab fa-linkedin mr-2"></i>
          </button>
        </div>
           -->
        <div>
          <button type="button" class="btn-secondary" @click="signWithFacebook">
            <font-awesome-icon class="mr-2" :icon="['fab', 'fa-facebook']" :size="'sm'"></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
